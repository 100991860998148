import type { BlockData } from '../../templates/Block'
import { mixins, theme } from '../../../styles'
import { Dot } from '../../atoms/Dot'

export function ArticleChapo({
  elementList,
  'data-module': dataModule,
}: BlockData<'article-chapo'>): JSX.Element {
  return (
    <>
      <h2 className="ArticleChapo flex flex-column" data-module={dataModule}>
        {elementList?.map(({ text }, index) => (
          <div className="ArticleChapo__List_Item flex" key={index}>
            <div className="ArticleChapo__Bullet">
              <Dot />
            </div>
            <span className={`ArticleChapo__Point ArticleChapo__Point__${index}`}>{text}</span>
          </div>
        ))}
      </h2>

      <style jsx>{`
        .ArticleChapo {
          margin: 0 ${theme.block.marginLR}px 20px;
        }

        .ArticleChapo__Bullet {
          position: relative;
          top: -0.2rem;
        }

        .ArticleChapo__Point {
          color: ${theme.cssVars.deepBlue};
          font-family: ${theme.cssVars.overpass};
          font-size: 20px;
          font-weight: 900;
          padding-left: 0.8rem;
          margin-top: 0;
        }

        .ArticleChapo__List_Item {
          align-items: baseline;
          padding-bottom: 20px;
        }

        @media ${mixins.mediaQuery.desktop} {
          .ArticleChapo__Point {
            font-size: 24px;
            line-height: 31px;
          }
        }
      `}</style>
    </>
  )
}
